.swiper-wrapper {
  align-items: center;
}

.arrow-left {
  left: -50px;
}

.arrow-right {
  right: -50px;
}

.swiper-pagination-bullet {
  border: 2px solid #fff;
  padding: 5px;
}
