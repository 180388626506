.opened {
  opacity: 1;
  transform: translate(0, 0) scale(1);
  pointer-events: visible;
}

.vision-tablet {
  display: flex;
}

.menu-opened {
  background: linear-gradient(90deg, #2301be, #280064);
}

.logo-width {
  width: 192px;
}

@media (max-width: 1199px) {
  .vision-tablet {
    display: none;
  }

  .logo-width {
    width: 118px;
  }
}
