.simulations-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.filter-simulation {
  background: linear-gradient(
    0deg,
    rgba(27, 3, 75, 0.6) 7.29%,
    hsla(0, 0%, 61.6%, 0) 53.65%
  );
}
